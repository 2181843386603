/* You can add global styles to this file, and also import other style files */
.overlay {
  width: 100%;
}
label.mat-checkbox-layout {
  white-space: normal;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.content-with-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
label.mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 0 8px 0 0;
}
#termsBlock .mat-checkbox-inner-container {
  margin-top: 0;
  margin-right: 16px;
}
.section-spacing--tier-4 {
  margin-top: 32px;
}
#errorMsg {
  color:#ff3b30;
  display:none;
}
.error-msg-false {
  margin-top: 32px;
}
.error-font-size {
  font-size: 75%;
}
.infoIcon {
  cursor: pointer;
  margin-left: 8px;
    font-size: 22px !important;
    color: rgb(115, 115, 115);
}
#formSideNav {
  max-width: 384px!important;
}

/* ========================
*  Motion overrides
=========================== */
@media (max-width: 799px) {
    .multiple-button--container .mat-button {
        margin: 8px 0;
    }
}
@media (min-width: 800px) {
    .multiple-button--container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}
.multiple-button--container .motion-button--large {
    min-height: 124px;
}
.multiple-button--container span {
    margin-bottom: 16px;
}

.failedStatus{
  color:red !important;
}

.successStatus{
  color:green;
}
